import React from 'react';
import { graphql, Link, useStaticQuery } from 'gatsby';
import { useLocalization } from 'gatsby-theme-i18n'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next'
import {
  Button, Form, FormGroup, Label, Input, FormText,
} from 'reactstrap';
import Blog01 from '../images/blog/blog.jpg';
import Layout from '../layouts/index';
import BlogArticle from '../components/Blog';
import Clients from '../components/Clients';
import ProductCLM1 from '../images/product-clm-1.png';
import Lawyer from '../images/lawyer.jpg';
import ProductCLM2 from '../images/product-clm-2.png';
import DemoForm from '../components/DemoForm';
import DashboardWaves from '../images/dashboard-starter-waves-es.svg';
import Dashboard from '../images/500-startups/dashboard-starter.svg';
import Dashboard2 from '../images/500-startups/dashboard-starter-2.svg';
import Logo500 from '../images/500-startups/logo-500.png';
import SemiCircle from '../images/500-startups/semi-circle.svg';
import MujerStarter from '../images/mujer-starter.jpg';
import ClmHero from '../images/trato-clm-hero.png';
import SignatureES from '../images/electronic-signature-es.png';
import Despachos from '../images/imac-despachos.png';

import Hero from '../images/hero.png';


function TratoPartners({ location }) {
  const { t } = useTranslation();
  const { locale } = useLocalization();

  return (
    <Layout id="landing" locale={locale} bodyClass="page-home landing" inverted border location={location}>

      <section className="bg-grey">
        <div className="container mt-5">
          <div className="row">
            <div className="col-lg-6 d-flex">
              <div className="align-self-center">
                <h1 className="mb-3">{t('tratoPartners.heroTitleOne')} <span className="font-weight-bold">{t('tratoPartners.heroTitleTwo')}</span></h1>
                <p className="text-muted mb-3">{t('tratoPartners.heroText')}.</p>
                <DemoForm type="trato-vs-docusign" />
              </div>
            </div>
            <div className="col-lg-6">
              <img src={SignatureES} className="img-fluid" />
            </div>
          </div>
        </div>
      </section>
      <section className="bg-light">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 d-flex">
              <img src={ProductCLM1} className="img-fluid align-self-center" />
            </div>
            <div className="col-lg-6 d-flex">
              <div className="align-self-center">
                <h5 className="font-weight-bold mb-2">{t('legalAdvisor.sectionOneSubtitle')}</h5>
                <ul className="list-unstyled">
                  <li className="text-muted mb-2"><FontAwesomeIcon icon={faCheckCircle} className="text-primary align-baseline icon-sm mr-1" /> {t('legalAdvisor.sectionOneFeature1')}</li>
                  <li className="text-muted mb-2"><FontAwesomeIcon icon={faCheckCircle} className="text-primary align-baseline icon-sm mr-1" />{t('legalAdvisor.sectionOneFeature2')}</li>
                  <li className="text-muted mb-2"><FontAwesomeIcon icon={faCheckCircle} className="text-primary align-baseline icon-sm mr-1" />{t('legalAdvisor.sectionOneFeature3')}</li>
                  <li className="text-muted mb-2"><FontAwesomeIcon icon={faCheckCircle} className="text-primary align-baseline icon-sm mr-1" />{t('legalAdvisor.sectionOneFeature4')}</li>
                  <li className="text-muted mb-2"><FontAwesomeIcon icon={faCheckCircle} className="text-primary align-baseline icon-sm mr-1" />{t('legalAdvisor.sectionOneFeature5')}</li>
                  <li className="text-muted mb-2"><FontAwesomeIcon icon={faCheckCircle} className="text-primary align-baseline icon-sm mr-1" />{t('legalAdvisor.sectionOneFeature6')}</li>
                  <li className="text-muted mb-2"><FontAwesomeIcon icon={faCheckCircle} className="text-primary align-baseline icon-sm mr-1" />{t('legalAdvisor.sectionOneFeature7')}</li>
                  <li className="text-muted"><FontAwesomeIcon icon={faCheckCircle} className="text-primary align-baseline icon-sm mr-1" />{t('legalAdvisor.sectionOneFeature8')}</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="bg-light">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 d-flex">
              <div className="align-self-center">
                <h2 className="font-weight-bold mb-2">Ofrece una personalización única a tus clientes</h2>
                <p className="lead text-muted">Trato brinda la mejor experiencia
                a la hora de gestionar y firmar contratos.
                </p>
              </div>
            </div>
            <div className="col-lg-6 d-flex">
              <img src={Despachos} className="img-fluid align-self-center" />
            </div>
          </div>
        </div>
      </section>


      <section className="bg-grey border-bottom">
        <div className="container">
          <div className="bg-light mx-auto rounded shadow-sm">
            <div className="row">
              <div className="col-lg-8 d-flex">
                <div className="align-self-center p-3">
                  <h2 className="font-weight-bold">{t('tratoClm.CtaTitle')}</h2>
                  <p className="lead text-muted mb-3">{t('tratoClm.CtaText')}</p>
                  <DemoForm type="trato-partners" />
                </div>
              </div>
              <div className="col-lg-4 d-none d-lg-block">
                <div className="position-relative">
                  <div className="gradient-cta position-absolute h-100 w-100 rounded-right" />
                  <p className="position-absolute text-light gradient-text mb-0">{t('tratoStarter.testimony1')} <span className="font-weight-bold">{t('tratoStarter.testimony2')}</span></p>
                  <img src={MujerStarter} className="img-fluid rounded-right" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

    </Layout>
  );
}

export default TratoPartners;
